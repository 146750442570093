<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Nuevo Doctor..." icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" />
						<!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedDoctores || !selectedDoctores.length" /> -->
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="doctores" v-model:selection="selectedDoctores" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} doctores">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Catálogo de Doctores</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<!-- <Column field="id_doctor" header="Id" :sortable="true"></Column> -->
					<Column field="nombre" header="Doctor" :sortable="true"></Column>
                    <!-- <Column field="tipo" header="Tipo" :sortable="true"></Column> -->
                    <Column field="especialidad" header="Especialidad" :sortable="true"></Column>
                    <Column field="apoyo" header="Apoyo" :sortable="true"></Column>
                    <Column field="fecha_alta" header="Alta" :sortable="true">
						<template #body="slotProps">
                                    {{computedDatesFormat(slotProps.data.fecha_alta)}}
                        </template>
					</Column>
                    <Column field="fecha_baja" header="Baja" :sortable="true">
						<template #body="slotProps">
                                    {{computedDatesFormat(slotProps.data.fecha_baja)}}
                        </template>
					</Column>
                    <Column field="activo" header="Activo" :sortable="true">
						<template #body="slotProps">
                                    {{turno(slotProps.data.activo)}}
                        </template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editDoctor(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteDoctor(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="doctorDialog" :style="{width: '850px'}" header="Doctor" :modal="true" class="p-fluid">

					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="clave">Clave *</label>
							<InputText id="clave" v-model.trim="doctor.clave" required="true" autofocus :class="{'p-invalid': submitted && !doctor.clave}" />
							<small class="p-invalid" v-if="submitted && !doctor.clave">La clave del doctor es requerida</small>
						</div>
						<div class="p-field p-col">
							<label for="contrasena">Contraseña *</label>
							<Password id="contrasena" v-model.trim="doctor.contrasena" required="true" feedback="true" autofocus :class="{'p-invalid': submitted && !doctor.contrasena}" />
							<small class="p-invalid" v-if="submitted && !doctor.contrasena">La contraseña es requerida</small>
						</div>
						<div class="p-field p-col">
							<label for="apoyo">Persona de apoyo</label>
							<Dropdown id="apoyo" name="apoyo" v-model="doctor.id_doctor_apoyo" :options="doctores" optionLabel="nombre" placeholder="Selecionar persona de apoyo ..." />
						</div>
					</div>

					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="nombre">Nombre *</label>
							<InputText id="nombre" v-model.trim="doctor.nombre" required="true" autofocus :class="{'p-invalid': submitted && !doctor.nombre}" />
							<small class="p-invalid" v-if="submitted && !doctor.nombre">El nombre de la doctor es requerido</small>
						</div>
						<div class="p-field p-col">
							<label for="especialidad">Especialidad *</label>
							<Dropdown id="especialidad" name="apoyo" v-model="doctor.id_especialidad" :options="especialidades" optionLabel="nombre" placeholder="Selecionar especialidad ..." autofocus :class="{'p-invalid': submitted && !doctor.id_especialidad}"  />
							<small class="p-invalid" v-if="submitted && !doctor.id_especialidad">La especialidad es requerida</small>
						</div>
						<div class="p-field p-col">
							<label for="cedulaProfesional">Cédula Profesional</label>
							<InputText id="cedulaProfesional" v-model.trim="doctor.cedula_profesional" />
						</div>
					</div>

					<div class="p-formgrid p-grid">						
						<div class="p-field p-col">
							<label for="email">Correo Electrónico</label>
							<InputText id="email" v-model.trim="doctor.email" />	
						</div>
						<div class="p-field p-col">
							<label for="telefono">Teléfono</label>
							<InputText id="telefono" v-model="doctor.telefono" />
						</div>
						<div class="p-field p-col">
							<label for="celular">Celular *</label>
							<InputText id="celular" v-model.trim="doctor.celular" autofocus :class="{'p-invalid': submitted && !doctor.celular}" />
							<small class="p-invalid" v-if="submitted && !doctor.celular">El celular es requerido</small>
						</div>
					</div>

					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="pais">Pais *</label>
							<Dropdown @click="PaisSelect" id="pais" name="pais" v-model="doctor.clave_pais" :options="paises" optionLabel="pais" placeholder="Selecionar pais..." autofocus :class="{'p-invalid': submitted && !doctor.clave_pais}" />
							<small class="p-invalid" v-if="submitted && !doctor.clave_pais">El pais es requerido</small>
						</div>
						<div class="p-field p-col">
							<label for="estado">Estado *</label>
							<Dropdown id="estado" name="estado" v-model="doctor.clave_estado" :options="estados" optionLabel="estado" placeholder="Selecionar Estado..." autofocus :class="{'p-invalid': submitted && !doctor.clave_estado}" />
							<small class="p-invalid" v-if="submitted && !doctor.clave_estado">El estado es requerido</small>
						</div>
						<div class="p-field p-col">
							<label for="municipio">Muncipio *</label>
							<InputText id="municipio" v-model="doctor.municipio" autofocus :class="{'p-invalid': submitted && !doctor.municipio}"/>
							<small class="p-invalid" v-if="submitted && !doctor.municipio">El municipio es requerido</small>
						</div>
					</div>

					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="calle">Calle y No. *</label>
							<InputText id="calle" v-model.trim="doctor.calle" autofocus :class="{'p-invalid': submitted && !doctor.calle}" />
							<small class="p-invalid" v-if="submitted && !doctor.calle">El calle y no es requerido</small>
						</div>
						<div class="p-field p-col">
							<label for="colonia">Colonia *</label>
							<InputText id="colonia" v-model.trim="doctor.colonia" autofocus :class="{'p-invalid': submitted && !doctor.colonia}" />
							<small class="p-invalid" v-if="submitted && !doctor.colonia">El colonia es requerido</small>
						</div>
						<div class="p-field p-col">
							<label for="cp">Código Postal *</label>
							<InputText id="cp" v-model.trim="doctor.cp" autofocus :class="{'p-invalid': submitted && !doctor.cp}" />
							<small class="p-invalid" v-if="submitted && !doctor.cp">El código postal es requerido</small>
						</div>
					</div>

					<div class="p-formgrid p-grid">
						
						<div class="p-field-checkbox p-col">
							<Checkbox id="activo" name="activo" :binary="true" value="Activo" v-model="doctor.activo" />
                        	<label for="activo">Activo</label>
						</div>
					</div>
					<!--
					<div class="p-field">
						<label for="direccion">Dirección</label>
						<Textarea id="direccion" v-model="doctor.direccion" required="false" rows="2" cols="20" />
					</div>
					-->

                    <!--
					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="activo">Activo</label>                            
							<InputNumber id="activo" v-model="doctor.activo" integeronly />
						</div>
					</div>
                    -->
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveDoctor" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDoctorDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="doctor">¿Desea eliminar el doctor <b>{{doctor.nombre}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDoctorDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteDoctor" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDoctoresDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="doctor">¿Desea eliminar los doctores seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDoctoresDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedDoctores" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import DoctorService from '../service/DoctorService';
import EspecialidadService from '../service/EspecialidadService';
import PaisService from '../service/PaisService';
import EstadoService from '../service/EstadoService';
import moment from 'moment';
import XLSX from 'xlsx'
import Cookies from "js-cookie"
window.Cookies = Cookies
export default {
	data() {
		return {
			doctores: null,
			doctorDialog: false,
			deleteDoctorDialog: false,
			deleteDoctoresDialog: false,
			doctor: {},
			selectedDoctores: null,
			filters: {},
			submitted: false,		 
			especialidades: null,
			paises: null,
			estados: [],
			doctorPost:[],
			doctorid:null,
			cedulapro:null,
			correo:null,
			telefono:null,
			OptionApoyo:null,
			OptionEspecialidad:null,
			OptionPais:null,
			OptionEstado:null,
			DoctoresExpor:null,
			useconver:null,
			userdata:null,
			fecha_baja:null,
			MuestrasActivas:null,
			EstadosPais:[],
		}
	},
	doctorService: null,
	especialidadService: null,
	paisService: null,
	estadoService: null,

	created() {
		this.doctorService = new DoctorService();
		this.especialidadService = new EspecialidadService();
		this.paisService = new PaisService();
		this.estadoService = new EstadoService();
	},
	mounted() {
		this.doctorService.getDoctores().then(data => {
				this.doctores = data; 
				console.log(data);
		});
		this.especialidadService.getEspecialidades().then(data => this.especialidades = data);
		this.paisService.getPaises().then(data => this.paises = data);
		this.estadoService.getEstados().then(data => this.EstadosPais = data);
		this.doctorService.getDoctoresExport().then(data => {this.MuestrasActivas = data.data2});
	},
	methods: {
		openNew() {
			this.doctor = {};
			this.submitted = false;
			this.doctorDialog = true;
			this.doctor.activo=true;
		},
		hideDialog() {
			this.doctorDialog = false;
			this.submitted = false;
		},
		saveDoctor() {
			this.submitted = true;
			this.useconver = Cookies.get("user");

			if(this.useconver != null || this.useconver !="null"){
				this.userdata=JSON.parse(this.useconver)												 
			}
			var d = new Date();
           
			if (this.doctor.nombre.trim()) {
				
				if (this.doctor.id_doctor>0) {
					 
					if( typeof this.doctor.cedula_profesional != 'undefined'){
						this.cedulapro=this.doctor.cedula_profesional;
					}

					if( typeof this.doctor.email != 'undefined'){
						this.correo=this.doctor.email;
					}

					if( typeof this.doctor.telefono != 'undefined'){
						this.telefono=this.doctor.telefono;
					} 
					
					if(this.doctor.id_doctor_apoyo != null){
						this.doctorid=this.doctor.id_doctor_apoyo.id_doctor;
					}else{
						this.doctorid=this.doctor.id_doctor_apoyo;
					}
					// if( typeof this.doctor.id_doctor_apoyo.id_doctor != 'undefined' || this.doctor.id_doctor_apoyo.id_doctor != 'null'){
					// 	this.doctorid=this.doctor.id_doctor_apoyo.id_doctor;
					// }
					if(this.doctor.activo==0){

						if(this.doctor.fecha_baja != null){
							this.fecha_baja=this.doctor.fecha_baja;
						}else{
							this.fecha_baja=this.computedDatesFormatSave(d);
						}
						
					} else{
						if(this.doctor.fecha_baja != null){
							this.fecha_baja=this.doctor.fecha_baja;
						} 
					}
					
					this.doctorPost.push({
						"id_doctor":this.doctor.id_doctor,
						"calle":this.doctor.calle,
						"nombre":this.doctor.nombre,
						"cedula_profesional":this.cedulapro,
						"celular":this.doctor.celular,
						"clave":this.doctor.clave,
						"clave_estado":this.doctor.clave_estado.clave_estado,
						"clave_pais":this.doctor.clave_pais.clave_pais,
						"colonia":this.doctor.colonia,
						"contrasena":this.doctor.contrasena,
						"cp":this.doctor.cp,
						"email":this.correo,
						"id_doctor_apoyo":this.doctorid,
						"id_especialidad":this.doctor.id_especialidad.id_especialidad,
						"municipio":this.doctor.municipio,
						"telefono":this.telefono, 
						"activo":this.doctor.activo,
						"fecha_baja":this.fecha_baja,
						"id_usuario_mod":this.userdata[0].id_usuario,
					});
					// console.log(this.doctorPost); 
					this.doctorService.updateDoctores(this.doctorPost[0]).then(document.location.reload());
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Doctor actualizado', life: 3000});
					
					this.doctorPost=[];
					this.doctorid=null;
					this.cedulapro=null;
					this.correo=null;
					this.telefono=null;

					

				}
				else {
					 
					if( typeof this.doctor.id_doctor_apoyo != 'undefined'){
						this.doctorid=this.doctor.id_doctor_apoyo.id_doctor
					}

					if( typeof this.doctor.cedula_profesional != 'undefined'){
						this.cedulapro=this.doctor.cedula_profesional;
					}

					if( typeof this.doctor.email != 'undefined'){
						this.correo=this.doctor.email;
					}

					if( typeof this.doctor.telefono != 'undefined'){
						this.telefono=this.doctor.telefono;
					}
					if(this.doctor.activo==0){

						if(this.doctor.fecha_baja != null){
							this.fecha_baja=this.doctor.fecha_baja;
						}else{
							this.fecha_baja=this.computedDatesFormatSave(d);
						}
						
					} else{
						if(this.doctor.fecha_baja != null){
							this.fecha_baja=this.doctor.fecha_baja;
						} 
					}

					this.doctorPost.push({
						"calle":this.doctor.calle,
						"nombre":this.doctor.nombre,
						"cedula_profesional":this.cedulapro,
						"celular":this.doctor.celular,
						"clave":this.doctor.clave,
						"clave_estado":this.doctor.clave_estado.clave_estado,
						"clave_pais":this.doctor.clave_pais.clave_pais,
						"colonia":this.doctor.colonia,
						"contrasena":this.doctor.contrasena,
						"cp":this.doctor.cp,
						"email":this.correo,
						"id_doctor_apoyo":this.doctorid,
						"id_especialidad":this.doctor.id_especialidad.id_especialidad,
						"municipio":this.doctor.municipio,
						"telefono":this.telefono, 
						"activo":this.doctor.activo,
						"fecha_alta":this.computedDatesFormatSave(d),
						"fecha_baja":this.fecha_baja,
						"id_usuario_reg":this.userdata[0].id_usuario,
						"id_usuario_mod":this.userdata[0].id_usuario,
					});
					 
					 
					this.doctorService.createDoctores(this.doctorPost[0]).then();
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Doctor creado', life: 3000});
					
					 
					this.doctorPost=[];
					this.doctorid=null;
					this.cedulapro=null;
					this.correo=null;
					this.telefono=null;
				 	document.location.reload();
				}

				this.doctorDialog = false;
				 
			}
		},
		editDoctor(doctor) {
			this.doctor = {...doctor};
			this.doctorDialog = true;

			this.OptionApoyo=this.doctor.id_doctor_apoyo;
			this.OptionEspecialidad=this.doctor.id_especialidad;
			this.OptionPais=this.doctor.clave_pais;
			this.OptionEstado=this.doctor.clave_estado;

			for(var i in this.doctores){
				if(this.OptionApoyo==this.doctores[i].id_doctor){
					
					this.doctor.id_doctor_apoyo = this.doctores[i];
				}                  	
			} 
			
			for(var i1 in this.especialidades){
				if(this.OptionEspecialidad==this.especialidades[i1].id_especialidad){
					
					this.doctor.id_especialidad = this.especialidades[i1];
				}                  	
			} 
			
			for(var i2 in this.paises){
				if(this.OptionPais==this.paises[i2].clave_pais){
					
					this.doctor.clave_pais = this.paises[i2];
				}                  	
			} 
			this.estados=[];
			for(var i31 in this.EstadosPais)
			{
				if(this.doctor.clave_pais.clave_pais==this.EstadosPais[i31].clave_pais)
					{ 					
						this.estados.push(this.EstadosPais[i31]);
					}                	
			} 
			if( typeof this.doctor.clave_pais != 'undefined'){
				for(var i3 in this.estados)
				{					
					if(this.OptionEstado==this.estados[i3].clave_estado)
					{ 					
						this.doctor.clave_estado=this.estados[i3];
					}                  	
				} 
			}
			 console.log(this.doctor);
		},
		confirmDeleteDoctor(doctor) {
			this.doctor = doctor;
			this.deleteDoctorDialog = true;
		},
		deleteDoctor() {
			this.useconver = Cookies.get("user");
			var validateFecha=true;
			if(this.useconver != null || this.useconver !="null"){
				this.userdata=JSON.parse(this.useconver)												 
			}
			var d = new Date();
			// this.doctores = this.doctores.filter(val => val.id !== this.doctor.id);
			this.deleteDoctorDialog = false;
			// this.doctorService.deleteDoctores(this.doctor).then();
			// this.doctor = {};
				if( typeof this.doctor.cedula_profesional != 'undefined'){
						this.cedulapro=this.doctor.cedula_profesional;
					}

					if( typeof this.doctor.email != 'undefined'){
						this.correo=this.doctor.email;
					}

					if( typeof this.doctor.telefono != 'undefined'){
						this.telefono=this.doctor.telefono;
					} 
					
					if(this.doctor.id_doctor_apoyo != null){
						this.doctorid=this.doctor.id_doctor_apoyo.id_doctor;
					}else{
						this.doctorid=this.doctor.id_doctor_apoyo;
					}
					// if( typeof this.doctor.id_doctor_apoyo.id_doctor != 'undefined' || this.doctor.id_doctor_apoyo.id_doctor != 'null'){
					// 	this.doctorid=this.doctor.id_doctor_apoyo.id_doctor;
					// }
					if(this.doctor.activo==0){

						if(this.doctor.fecha_baja != null){
							this.fecha_baja=this.doctor.fecha_baja;
						}else{
							this.fecha_baja=this.computedDatesFormatSave(d);
						}
						
					} else{
						if(this.doctor.fecha_baja != null){
							this.fecha_baja=this.doctor.fecha_baja;
						} 
					}
					
					

					this.doctorPost.push({
						"id_doctor":this.doctor.id_doctor,
						"calle":this.doctor.calle,
						"nombre":this.doctor.nombre,
						"cedula_profesional":this.cedulapro,
						"celular":this.doctor.celular,
						"clave":this.doctor.clave,
						"clave_estado":this.doctor.clave_estado.clave_estado,
						"clave_pais":this.doctor.clave_pais,
						"colonia":this.doctor.colonia,
						"contrasena":this.doctor.contrasena,
						"cp":this.doctor.cp,
						"email":this.correo,
						"id_doctor_apoyo":this.doctorid,
						"id_especialidad":this.doctor.id_especialidad,
						"municipio":this.doctor.municipio,
						"telefono":this.telefono, 
						"activo":0,
						"fecha_baja":this.fecha_baja,
						"id_usuario_mod":this.userdata[0].id_usuario,
					});
					
					 for(var i in this.MuestrasActivas)
					 {
						if(this.doctor.id_doctor==this.MuestrasActivas[i].id_doctor)
						{ 
							validateFecha=false;
						}                  	
					} 
					if(validateFecha==true){
						this.doctorService.updateDoctores(this.doctorPost[0]).then(document.location.reload());
						this.$toast.add({severity:'success', summary: 'Successful', detail: 'Doctor eliminado', life: 3000});
					 
					}else{
						this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'No se puede eliminar.El doctor tiene muestras asignadas', life: 3000});
					}
					
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.doctores.length; i++) {
				if (this.doctores[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
            let id = this.doctores.length + 1;
            /*
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            */
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteDoctoresDialog = true;
		},
		deleteSelectedDoctores() {
			this.doctores = this.doctores.filter(val => !this.selectedDoctores.includes(val));
			this.deleteDoctoresDialog = false;
			this.selectedDoctores = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Doctores eliminados', life: 3000});
			
		},
		turno(value){
			if(value ==1){
				return 'Activo'
			}else{
				return 'Inactivo'
			}
		},
		computedDatesFormat(value) {
			if(value !=null){
                return moment(value).format('DD/MM/YYYY')
			}
		},
		download : function() {
			this.doctorService.getDoctoresExport().then(data => {
				console.log(data);
			this.DoctoresExpor = data.doctores
				if(this.DoctoresExpor != null){
				const data = XLSX.utils.json_to_sheet(this.DoctoresExpor)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'CatalogoDoctores.xlsx')
			}
			});
		},
		computedDatesFormatSave(value) {
                return moment(value).format('YYYY/MM/DD')
		},
		PaisSelect(){
			this.estados=[];
			if( typeof this.doctor.clave_pais != 'undefined'){
				for(var i in this.EstadosPais)
				{					
					if(this.doctor.clave_pais.clave_pais==this.EstadosPais[i].clave_pais)
					{ 					
						this.estados.push(this.EstadosPais[i]);
					}                  	
				} 
			}
			
			
		}
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.doctor-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .doctor-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.doctor-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}
</style>
